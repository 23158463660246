import MyNavbar from './MyNavbar';
import Home from './Home';
import WorkCulture from './WorkCulture';
import OurVision from './OurVision';
import Projects from './Projects';


function App() {
  return (
    <div>
      <MyNavbar />
      <Home />
      <WorkCulture />
      <OurVision />
      <Projects />
    </div>
  );
}

export default App;
