import './style.css';
import '../App.css';
import title from "../assets/title.png";
import apple from "../assets/apple.png";
import google from "../assets/google.png";
const Game = () => {

    return (
        <div className='gamepage'>
            {/* <h1>2987</h1>
            <br /><h3>Flight to the future</h3> */}
            <img className='gametitle' src={title} alt="2987 flight to the future" />

            <div className='button-container'>
                <a href="https://apps.apple.com/in/app/2987-flight-to-the-future/id6483931943" target="_blank" role="button">
                <img src={apple} />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.Decodgame.ChennaiFly&pcampaignid=web_share" target="_blank" role="button">
                <img src={google} />
                </a>
            </div>
        </div>
    )
}
export default Game;
