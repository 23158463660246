import './style.css';
import '../App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useTable, useSortBy } from 'react-table';

const Leaderboard = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const fetchUsers = useCallback(async () => {
        setIsRefreshing(true); 
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            filters: {
                offset: 0,
                limit: 100 // Set a high limit to fetch all records
            }
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://apidecodgames.site/user/list", requestOptions);
            const result = await response.json();
            setUsers(result.users);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }finally {
            setIsRefreshing(false); // Hide loader after fetching
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const data = React.useMemo(() => users, [users]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Rank',
                accessor: (row, index) => index + 1
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Score',
                accessor: 'score'
            },
            {
                Header: 'Coin',
                accessor: 'coin'
            },
            {
                Header: 'Life',
                accessor: 'life'
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate',
                Cell: ({ row }) => formatDate(row.original.userCreatedAt).date
            },
            {
                Header: 'Last Updated Date',
                accessor: 'lastUpdatedDate',
                Cell: ({ row }) => formatDate(row.original.updatedAt).date
            },
            {
                Header: 'Last Updated Time',
                accessor: 'lastUpdatedTime',
                Cell: ({ row }) => formatDate(row.original.updatedAt).time
            },
            {
                Header: 'Email',
                accessor: 'email',
                className: 'email'
            },
            {
                Header: 'Phone',
                accessor: 'phone'
            },
            {
                Header: 'Created Time',
                accessor: 'createdTime',
                Cell: ({ row }) => formatDate(row.original.userCreatedAt).time
            },
            {
                Header: 'Updated Date',
                accessor: 'updatedDate',
                Cell: ({ row }) => formatDate(row.original.userUpdatedAt).date
            },
            {
                Header: 'Updated Time',
                accessor: 'updatedTime',
                Cell: ({ row }) => formatDate(row.original.userUpdatedAt).time
            },
            {
                Header: 'Is Tutorial Shown',
                accessor: 'isTutorialShown',
                Cell: ({ value }) => (value ? 'Yes' : 'No')
            },
            {
                Header: 'Coin By Ad',
                accessor: 'coinByAd'
            },
            {
                Header: 'Coin By Play',
                accessor: 'coinByPlay'
            },
            {
                Header: 'Total Ad',
                accessor: 'totalAd'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data }, useSortBy);

    if (loading && !isRefreshing) return <div className="loading">Loading...</div>; 
    if (error) return <div className="error">Error: {error.message}</div>;

    return (
        <div className="table-container">
            <div className="toppers-header">
                <h3>2987 Survival future game - Top players</h3>
            <button onClick={fetchUsers} className="refresh-button">
            {isRefreshing ? <div className="loader"></div> : 'Refresh'}
            </button>
           </div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} data-label={column.render('Header')}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} data-label={cell.column.render('Header')}><span>{cell.render('Cell')}</span></td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[date.getMonth()]; // Get the month name
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    hours = String(hours).padStart(2, '0'); // Add leading zero for single-digit hours

    const formattedDate = `${day} ${month}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return { date: formattedDate, time: formattedTime };
};


export default Leaderboard;
