import './style.css';
import '../App.css';
const Home = () => {

    return (
        <section className="welcome_page">
            <div className='container main' id='firstpage'>
                <div className='pageheading'>
                    <h1>Welcome to
                        <span className='dcod'>DecoD</span>
                        Games</h1>
                    <h6>WHERE GAMING COMES TO LIFE!</h6>
                </div>
                <div className='content'>
                    <p>At DecodGames, we're more than just a gaming company. we're a hub of creativity, innovation, and limitless possibilities. Our passion for gaming drives us to create captivating experiences that resonate with players of all ages. Step into a world where pixels transform into adventures, and where every click, swipe, and tap is an invitation to explore new realms.</p>
                </div>
            </div>
        </section>
    )
}
export default Home;
