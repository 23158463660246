import './style.css';
import '../App.css';


const WorkCulture = () => {

    return (
        <section className="second_page" id='secondpage'>
            <div className='container main' id='firstpage'>
                <div className='pageheading'>
                    <h6>MORE THEN GAMES</h6>
                    <h1>An Evolution Of
                        <span className='dcod'>Proactive</span>
                        Development
                    </h1>
                </div>
                <div className='content'>
                    <p>Step into a culture where  game development transcends the mere completion of tasks.Here at DecodGames, we thrive on proactive development-an environment where creativity and innovation flourish . Our team is not  bound by conventinoal limitiations.We're driven by the spirit of pushing boundaries,sparking ideas,and transforming visions into reality. We believe that every line of code, every pixel,and every concept contributes to the immersive tapestry of our games.
                    </p></div>
            </div>
        </section>
    )
}
export default WorkCulture;
