import './style.css';
import '../App.css';


const Projects = () => {

    return (
        <section className="fourth_page" id='fourthpage'>
            <div className='container main'>
                <div className='pageheading'>
                    <h6>OUR FIRST MOBILE GAME</h6>
                    <h1>
                        <span className='dcod'>2987-</span>
                        Flight the future</h1>

                </div>
                <div className='content'>
                    <p>
                        An exhilarating single-touch mobile game  that invites players to embark on a high-flying adventure like no other.Navigate through thrilling
                                challenges with just a tap of your fingers.Soar through the skies,dodging obstacles and collecting rewards to  achieve the highest score possible.
                                with its intuitive controls and addictive gameplay,"2987-flight to the future" offers a seamless and immersive experience for players of all ages.
                                showcase your piloting skills,each more breathtaking than the last.Compete againest friends and players  from around the world on the global 
                                leaderboard to secure your place among the top high scorers.
                    </p>

                </div>

            </div>
        </section>
    )
}
export default Projects;
