import './style.css';
import '../App.css';


const OurVision = () => {

  return (
    <section className="third_page" id='thirdpage'>
      <div className='container main'>
        <div className='pageheading'>
          <h6>EMBRACE THE MOBILE REVALUATION</h6>
          <h1> Our <span className='dcod'>Vision</span> for Tomorrow's Gamers</h1>

        </div>
        <div className='content'>
          <p>
            Our vision is anchored in the belief that the mobile device in your hand is a portal to limitless adventures. We envision a future where mobile gaming blurs the lines between reality and imagination, where every game transports you to a world where possibilities are boundless. With each release, we inch closer to turning this vision into a thrilling reality that players can touch, feel, and experience.
          </p>

        </div>
      </div>
    </section>
  )
}
export default OurVision;