import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Games from './Games'
import Leaderboard from './Leaderboard'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/games/2987" element={<Games />} />
        <Route path="/games/2987/leaderboard" element={<Leaderboard />} />
      </Routes>
    </Router>
  </React.StrictMode>
);