import './style.css';
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef, useState } from 'react';
import logo from "../assets/Logo.png";

const MyNavbar = () => {
    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

    const [active, setActive] = useState('');
    console.log(active);
    return (
        <div className='navbaritems'>
            <h1 className='navbar-logo'>
                <img src={logo} alt="DecodGames" /></h1>
            <nav ref={navRef}>
                <a href='#secondpage' className={active === 'second' ? 'menu-active' : ''} onClick={() => {
                    showNavbar();
                    setActive("second")
                }}>WORK CULTURE</a>
                <a href='#thirdpage' className={active === 'third' ? 'menu-active' : ''} onClick={() => {
                    showNavbar();
                    setActive("third")
                }}>OUR VISION</a>
                <a href='#fourthpage' className={active === 'fourth' ? 'menu-active' : ''} onClick={() => {
                    showNavbar();
                    setActive("fourth")
                }}>OUR PROJECTS</a>

                <button className='nav-btn nav-close-btn'
                    onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>

            <button className='nav-btn'
                onClick={showNavbar}>
                <FaBars />
            </button>
        </div>


    )

}

export default MyNavbar;
